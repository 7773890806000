import chubby from './chubby.png';
import './App.css';

function App() {

  const daysLeft = () => {
    let today = new Date();
    var cmas = new Date(2024, 1, 13); // 0 index = Jan

    var one_day=1000*60*60*24;
    let left = Math.ceil((cmas.getTime() - today.getTime()) / (one_day));

    return left;
  }

  const daysPast = () => {
    let today = new Date();
    var past = new Date(2023, 9, 21); // 0 index = Jan


    var one_day=1000*60*60*24;

    return Math.ceil(
        (today.getTime() - past.getTime()) / (one_day)
      )
  }

  const daysUntil = (year, month, date) => {
    let today = new Date();
    var cmas = new Date(year, (month-1), date); // 0 index = Jan

    var one_day=1000*60*60*24;

    return Math.ceil(
        (cmas.getTime() - today.getTime()) / (one_day)
      )
  }

  const remainDays = daysLeft();

  return (
    <div className="App">
      <header className="App-header">
        <img src={chubby} className={remainDays <= 0 ? "App-logo-here" : "App-logo"} alt="logo" />
        <p className="days-p">
          {(remainDays <= 0 ) ?
            <>
              <span className="days">Magician is here!</span>
            </> :
            <>
              <span className="days">{remainDays}</span> days
              <br />
              <span className="until">until magician sees chubby ❤️</span>
            </>}
        </p>
        <p>
          {daysPast()} days have passed ✅
        </p>
      </header>
      <section>
        <h2>Chulalongkorn Deadlines</h2>
        <div className="table">
          <table>
            <thead>
              <tr>
                <td>Schedule</td>
                <td>Deadlines</td>
                <td>Days Until</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Online Application Submission</td>
                <td>Dec 1, 2023 - Mar 31, 2024</td>
                <td>{daysUntil(2024, 3, 31)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
}

export default App;
